import { createApp } from 'vue'
import App from './App.vue'
import router from "@/router";
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
// import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import animate from 'animate.css'
import VueWechatTitle from 'vue-wechat-title'

const app = createApp(App);

// for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    //     app.component(key, component)
    // }

    app.use(router);
    app.use(ElementPlus);
    app.use(animate);
    app.use(VueWechatTitle)

app.mount('#app');