const routes = [
    {
        path: "/",
        component: () => import("@/container/LayOut"),
        children: [
            {
                path: "/",
                meta: {
                    title: '一个无聊的网站'
                },
                component: () => import("@/view/HomePage")
            },
            {
                path: "/game",
                meta: {
                    title: '大战猪猪'
                },
                component: () => import("@/components/VsGame")
            },
            {
                path: "/transition",
                meta: {
                    title: '在线转换'
                },
                component: () => import("@/components/TransitionSwitch")
            },
        ]
    },
    {
        path: "/time",
        meta: {
            title: '时间'
        },
        component: () => import("@/view/DateTime")
    },
    {
        path: "/:pathMatch(.*)*",
        meta: {
            title: '404'
        },
        component: () => import("@/view/NotFound")
    },
]

export default routes;