import * as Router from "vue-router";
import routes from "@/router/routes";

const router = Router.createRouter({
    history: Router.createWebHashHistory(),
    routes,
});

// router.beforeEach((to, from, next) => {
//     document.title = to.meta.title ? to.meta.title : '一个无聊的网站';
//     // to.matched 是一个数组（匹配到是路由记录）
//     // to.matched.some(record => record.meta.requiresAuth)
//     next()
// })

export default router;